body {
  background-color: #fafafa;
  font-family: "Segoe UI", Meiryo, system-ui, -apple-system, BlinkMacSystemFont, sans-serif;
}

.active {
  color: #f44336;
}

.excellent {
  color: #43a047;
}

.miss-grid .miss-card .MuiCardContent-root {
  padding-top: 0.5rem;
}

.miss-grid .miss-card .MuiCardContent-root h1 {
  text-align: center;
  font-size: 1rem;
  margin-top: 0;
  margin-bottom: 0;
}

.miss-grid .miss-card .MuiCardContent-root p {
  text-align: center;
  font-size: 0.8rem;
  margin-bottom: 0;
}

.miss-grid .miss-card.active {
  background-color: #f44336;
}

.miss-grid .miss-card.active2 {
  background-color: #f44336;
}

.miss-grid .miss-card.active3 {
  background-color: #f44336;
}

.miss-grid .miss-card.active h1 {
  color: white;
}

.miss-grid .miss-card.active2 h1 {
  color: white;
}

.miss-grid .miss-card.active3 h1 {
  color: white;
}

.miss-grid .miss-card.active p {
  color: white;
}

.miss-grid .miss-card.active2 p {
  color: white;
}

.miss-grid .miss-card.active3 p {
  color: white;
}

.MuiGrid-spacing-xs-1.keyboard {
  margin-bottom: 1rem;
}

/* .analyticsCard.active {
  background-color: #f44336;
}

.analyticsCard.active h1 {
  color: white;
}
.analyticsCard.active p {
  color: white;
}

.analyticsCard.excellent {
  background-color: #43a047;
}

.analyticsCard.excellent h1 {
  color: white;
}
.analyticsCard.excellent p {
  color: white;
} */



.MuiPaper-root.MuiCard-root.analyticsCard {
  text-align: center;
  margin-bottom: 0.5rem;
}

.MuiPaper-root.MuiCard-root.analyticsCard .MuiCardContent-root {
  padding-bottom: 16px;
}

.MuiPaper-root.MuiCard-root.analyticsCard .MuiCardContent-root h1 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 1.2rem;
}


pre code.hljs {
  padding: 1.2rem;
} 

@keyframes scaleUpDown {

  0%,
  100% {
      transform: scaleY(1) scaleX(1);
  }

  50%,
  90% {
      transform: scaleY(1.1);
  }

  75% {
      transform: scaleY(0.95);
  }

  80% {
      transform: scaleX(0.95);
  }
}
@keyframes particleUp {
  0% {
      opacity: 0;
  }

  20% {
      opacity: 1;
  }

  80% {
      opacity: 1;
  }

  100% {
      opacity: 0;
      top: -50%;
      transform: scale(0.5);
  }
}
.fire-main {
  position: absolute;
  left: auto;
  height: 2.5rem;
  width: 2.5rem;
  animation: scaleUpDown 3s ease-out;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
}

.fire-main .main-fire {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: radial-gradient(farthest-corner at 10px 0, #d43300 0%, #fd8a43 95%);
  transform: scaleX(0.8) rotate(45deg);
  border-radius: 0 40% 60% 40%;
  filter: drop-shadow(0 0 10px #f0a12b);
}

.fire-main .particle-fire {
  position: absolute;
  top: 60%;
  left: 45%;
  width: 10px;
  height: 10px;
  background-color: #ef5a00;
  border-radius: 50%;
  filter: drop-shadow(0 0 10px #d43322);
  animation: particleUp 2s ease-out 0;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
}